import { Controller } from "@hotwired/stimulus"
import { Swipers } from "../web/dx/components/Swipers";

// Connects to data-controller="jolifish-swiper"
export default class extends Controller {
  connect() {
    this.swiper = new Swipers().init(this.element);
  }

  disconnect() {
    this.swiper?.destroy();
    this.swiper = undefined;
  }

  nextSlide() {
    const el = this.swiper?.navigation?.nextEl;
    if (!el) return;

    if (el.parentElement.nodeName === "A") {
      el.parentElement.click();
    } else {
      this.swiper.slideNext();
    }
  }

  prevSlide() {
    const el = this.swiper?.navigation?.prevEl;
    if (!el) return;

    if (el.parentElement.nodeName === "A") {
      el.parentElement.click();
    } else {
      this.swiper.slidePrev();
    }
  }
}
