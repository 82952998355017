import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin-bar"
export default class extends Controller {
  handleHide = () => {
    localStorage.removeItem("ADMINBAR_SHOWN");
  };

  handleShow = () => {
    localStorage.setItem("ADMINBAR_SHOWN", "TRUE");
  };

  connect() {
    if (localStorage.getItem("ADMINBAR_SHOWN")) {
      this.element.classList.add("show");
    }

    this.element.addEventListener("hide.bs.collapse", this.handleHide);
    this.element.addEventListener("show.bs.collapse", this.handleShow);
  }

  disconnect() {
    this.element.removeEventListener("hide.bs.collapse", this.handleHide);
    this.element.removeEventListener("show.bs.collapse", this.handleShow);
  }
}
