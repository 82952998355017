import { Controller } from "@hotwired/stimulus"
import * as R from "remeda";
import { Offcanvas } from 'bootstrap';

// Connects to data-controller="header-search"
export default class extends Controller {
  static targets = ["search", "suggestions", "wrapper"];

  connect() {
    const config = (window.outerWidth > 1024) ? { backdrop: true, scroll: false } : { backdrop: false, scroll: true }
    const overlay = new Offcanvas(this.wrapperTarget, config);

    const debounceSearch = R.debounce((target) => {
      target.form.requestSubmit();
      overlay.show();
    }, { timing: "trailing", waitMs: 300 });

    this.searchTargets.forEach((target) => {
      target.addEventListener("input", (e) => {
        debounceSearch.call(target);
      });
    });
  }

  suggestionsTargetConnected(element) {
    element.addEventListener("click", () => {
      this.searchTargets.forEach((target) => {
        if (target.value !== "") {
          target.value = element.textContent;
          target.form.requestSubmit();
        }
      });
    });
  }

  wrapperTargetConnected(element) {
    const searchTargets = this.searchTargets;

    element.addEventListener('hidden.bs.offcanvas', function (e) {
      searchTargets.forEach((target) => {
        target.value = "";
      });
    })

    element.addEventListener('shown.bs.offcanvas', function (e) {
      if (window.outerWidth <= 1024) {
        searchTargets[0].focus();
      } else {
        searchTargets[1].focus();
      }
    })
  }
}
