import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "thumbnail", "productVariantName", "formFrame", "form", "productVariantIdInput"];

  disconnect() {
    this.stopCarousel();
  }

  startCarousel() {
    this.stopCarousel();
    let next = this.nextVariantElement();
    if (next) {
      this.carouselTimer = setInterval(this.selectNext.bind(this), 2500);
      this.preload(next);
    }
  }

  stopCarousel() {
    if (this.carouselTimer) {
      clearInterval(this.carouselTimer);
      this.carouselTimer = null;
    }
  }

  select(event) {
    let clicked = event.target.closest(".product-box-alternate");
    if (!clicked) return;
    event.preventDefault();
    this.selectVariantElement(clicked);
  }

  selectNext() {
    this.selectVariantElement(this.nextVariantElement());
    this.preload(this.nextVariantElement());
  }

  nextVariantElement() {
    return (
      this.element.querySelector(".product-box-alternate.--selected ~ .product-box-alternate") ||
      this.element.querySelector(".product-box-alternate")
    );
  }

  selectVariantElement(clicked) {
    this.linkTarget.href = clicked.dataset.productVariantUrl;
    this.thumbnailTarget.src = clicked.dataset.thumbnailSrc;
    this.productVariantNameTarget.textContent = clicked.dataset.productVariantName;

    if (this.formFrameTarget) {
      this.formFrameTarget.id = clicked.dataset.formFrameId;
    }

    if (this.formTarget) {
      this.formTarget.dataset.itemId = clicked.dataset.productVariantId;
      this.formTarget.dataset.itemName = clicked.dataset.productVariantName;
    }

    if (this.productVariantIdInputTarget) {
      this.productVariantIdInputTarget.value = clicked.dataset.productVariantId;
    }

    let previouslySelected = this.element.querySelector(".product-box-alternate.--selected");
    if (previouslySelected) previouslySelected.classList.remove("--selected");

    if (!clicked.classList.contains("--selected")) {
      clicked.classList.add("--selected");
    }
  }

  preload(variantElement) {
    (new Image()).src = variantElement.dataset.thumbnailSrc;
  }
}
