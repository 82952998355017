import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-reload"
export default class FormReloadController extends Controller {
  static targets = ['inputs'];

  inputsTargetConnected(target) {
    target.addEventListener('change', this.reloadForm);
  }

  inputsTargetDisconnect(target) {
    target.removeEventListener('change', this.reloadForm);
  }

  reloadForm = (event) => {
    const target = event.currentTarget;
    const form = target.form;

    target.parentNode.appendChild(this.createHiddenFormInput('reload', 'true'));
    target.parentNode.appendChild(this.createHiddenFormInput('reload_submitter', this.element.id));

    // requestSubmit() goes through Turbo Drive, while submit() would not.
    // Also, because requestSubmit() requires the form to pass validation, we temporarily set noValidate.
    const noValidate = form.noValidate;
    form.noValidate = true;
    form.requestSubmit();
    form.noValidate = noValidate;
  }

  createHiddenFormInput(name, value) {
    const newInput = document.createElement('input');
    newInput.setAttribute('type', 'hidden');
    newInput.setAttribute('name', name);
    newInput.setAttribute('value', value);
    return newInput;
  }
}
