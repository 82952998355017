import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["keycode"];

  connect() {
    if (this.keycodeTargets.length > 0) {
      document.addEventListener("keydown", this.handleKeyDown.bind(this));
    }
  }

  handleKeyDown(event) {
    if (event.target.tagName.toLowerCase() === "input" || event.target.tagName.toLowerCase() === "textarea") {
      return; // Skip execution if the event occurs in an input field or textarea
    }

    this.keycodeTargets.forEach((item)=>{
      if (event.key === item.dataset.keyboardKey) {
        this.visitPage(item);
      }
    })
  }

  visitPage(item) {
    const url = item.getAttribute("href");
    if (url) {
      window.location.href = url;
    }
  }
}
