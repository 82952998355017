import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="preserve-scroll"
export default class extends Controller {
  connect() {
    window.preserveScroll ||= {};

    this.element.addEventListener("scroll", this.onElementScroll.bind(this));

    this.restoreScroll();
  }

  disconnect() {
    super.disconnect();

    this.element.removeEventListener("scroll", this.onElementScroll);
  }

  onElementScroll() {
    window.preserveScroll[this.element.id] = this.element.scrollTop;
  }

  restoreScroll() {
    if (window.preserveScroll[this.element.id]) {
      this.element.scrollTo(0, window.preserveScroll[this.element.id]);
    }
  }
}
