import { Controller } from "@hotwired/stimulus";

import IMask from "imask";

// Connects to data-controller="input-mask"
export default class extends Controller {
  static targets = ["format"];

  formatTargetConnected(target) {
    IMask(target, {
      mask: target.dataset.inputMaskFormat,
      lazy: false,
      blocks: {
        money: {
          mask: Number,
          scale: 2,
          thousandsSeparator: "",
          padFractionalZeros: true,
          radix: "."
        },
        monthYear: {
          mask: "MM/YYYY",
          lazy: false,
          blocks: {
            MM: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              maxLength: 2,
              placeholderChar: "M"
            },
            YYYY: {
              mask: IMask.MaskedRange,
              from: 2024,
              to: 2099,
              placeholderChar: document.body.dataset.locale === "fr" ? "A" : "Y"
            }
          }
        },
        anything: {
          mask: /(.*?)/
        }
      }
    });
  }
}
