import { Controller } from "@hotwired/stimulus"
import TomSelect from '@remotedevforce/tom-select';
import { loadTomSelect } from "../libs/tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {
  static values = {
    settings: Object
  };

  connect() {
    if (this.element.classList.contains('tomselected')) return;
    if (this.element.classList.contains('no-ts')) return;

    if (this.hasSettingsValue) {
      this.ts = new TomSelect(this.element, this.settingsValue);
    } else if (this.element.classList.contains('tomselect-ajax')) {
      this.ts = loadTomSelect(this.element, true);
    } else {
      this.ts = loadTomSelect(this.element, false);
    }
  }

  disconnect() {
    if (this.ts) {
      this.ts.destroy();
      this.ts = null;
    }

    if (this.element.classList.contains('tomselected')) {
      this.element.classList.remove('tomselected');
    }
  }
}
