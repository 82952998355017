import { Controller } from "@hotwired/stimulus";
import "../libs/pie";

export default class extends Controller {
  connect() {
    window.initPieCharts([this.element]);
  }
  disconnect() {
    this.element.innerHTML = "";
  }
}
