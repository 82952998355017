import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-visibility"

export default class extends Controller {
    static targets = ["element", "triggerBtn"]

    connect() {
    }

    triggerBtnTargetConnected(target) {
        target.addEventListener('click', () => {
            if (target.dataset.hideSelf === "true") {
                target.classList.add("d-none");
            }
            this.elementTarget.classList.toggle("d-none");
        });
    }
}