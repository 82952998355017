import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input";
import intlTelInputUtils from "intl-tel-input/build/js/utils?url";

// Connects to data-controller="international-phone-input"
export default class extends Controller {
  static targets = ["phone", "fullphone"];

  connect() {
    this.iti = this.init();
  }

  onInput() {
    this.fullphoneTarget.value = `+${this.iti.getSelectedCountryData().dialCode} ${event.target.value}`;
  }

  init() {
    return intlTelInput(this.phoneTarget, {
      autoPlaceholder: 'aggressive',
      initialCountry: 'ca',
      separateDialCode: true,
      strictMode: true,
      formatOnDisplay: true,
      utilsScript: intlTelInputUtils,
    });
  }
}
