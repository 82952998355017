import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap";
import TextPlugin from "gsap/TextPlugin";
gsap.registerPlugin(TextPlugin);

// Connects to data-controller="text-animation"
export default class extends Controller {
  static targets = ['words']

  connect() {
    this.windowWidth = null;

    if (this.wordsTargets.length > 0) {
      this.timeline = gsap.timeline({ repeat: -1 });
      this.wordsTargets.forEach((element, index) => {
        this.timeline.add(gsap.to(element,
          {
            delay: 0.5,
            duration: 0.5,
            width: "100%",
            zIndex: 10,
            ease: "linear"
          }))
        this.timeline.add(gsap.to(element,
          {
            delay: 0.6,
            width: "0%",
            duration: 0.15,
            zIndex: -1,
            ease: "linear"
          }))

      });
      this.onResize();
    }
  }

  onResize = () => {
    if (this.windowWidth != window.innerWidth) {
      this.element.style.width = null;

      let widest = 0;

      this.wordsTargets.forEach(word => {
        const wordSpan = word.querySelector("span");
        if (wordSpan.offsetWidth > widest) {
          widest = wordSpan.offsetWidth;
        }
      });
      this.element.style.width = widest + "px";

    }
    this.windowWidth = window.innerWidth;
  }

  disconnect() {
    this.timeline.kill();
  }
}
