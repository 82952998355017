import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-quantity"
export default class extends Controller {
  static targets = ['content']

  increment() {
    const newQuantity = parseInt(this.contentTarget.value) + 1
    this.contentTarget.value = newQuantity
  }

  decrement() {
    if (this.contentTarget.value > 1) {
      this.contentTarget.value = parseInt(this.contentTarget.value) - 1
    }
  }
}
