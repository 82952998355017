import {Controller} from "@hotwired/stimulus"
import {Uppy, XHRUpload, StatusBar,} from 'uppy'

import English from '@uppy/locales/lib/en_US.js'
import French from '@uppy/locales/lib/fr_FR.js'

// Connects to data-controller="upload"
export default class extends Controller {
  static targets = ["fileInput", "cachedFileInput", "statusBar", "filesList"]

  uppyLocale() {
    return document.body.dataset.locale === "fr" ? French : English;
  }

  fileInputTargetConnected(element) {
    element.name = "";  // Make sure the content of this input is never submitted to the server.

    this.initializeUppy();
    this.initializeEventListeners();

    this.submitButton = element.form.querySelector('input[type="submit"]');
  }

  filesListTargetConnected(element) {
    this.filesList = document.createElement("ul");
    this.filesList.classList.add("uppy-file-list");
    element.appendChild(this.filesList);
    this.refreshFilesList();
  }

  onFileUploadStart(files) {
    this.submitButton.disabled = true;
  }

  onFileUploadDone(file, response) {
    const newValue = JSON.parse(this.cachedFileInputTarget.value || "[]");
    newValue.push(response.body.data);
    this.cachedFileInputTarget.value = JSON.stringify(newValue);
    this.refreshFilesList();
  }

  onAllFilesUploadDone(result) {
    this.submitButton.disabled = false;
  }

  onUploadCancel() {
    this.cachedFileInputTarget.value = null;
    this.submitButton.disabled = false;
  }

  onDeleteFile(e, file) {
    e.preventDefault();

    const uppyFile = this.uppy.getFiles().find((f) => f.response.body.data.id === file.id);

    if (uppyFile) {
      // Only useful because Uppy prevents re-uploading the same file and keep track of that internally.
      this.uppy.removeFile(uppyFile.id);
    }

    const filteredFiles = JSON
      .parse(this.cachedFileInputTarget.value || "[]")
      .filter((uploadedFile) => uploadedFile.id !== file.id);

    this.cachedFileInputTarget.value = JSON.stringify(filteredFiles)

    this.refreshFilesList();
  }

  initializeUppy() {
    this.uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: true,
      showSelectedFiles: true,
      locale: this.uppyLocale()
    }).use(StatusBar, {
      target: this.statusBarTarget,
      hideAfterFinish: false,
      showProgressDetails: true
    }).use(XHRUpload, {
      endpoint: this.element.dataset.uploadUrl
    });
  }

  initializeEventListeners() {
    this.uppy.on('upload-success', (file, response) => this.onFileUploadDone(file, response));
    this.uppy.on('complete', (result) => this.onAllFilesUploadDone(result));
    this.uppy.on('files-added', (files) => this.onFileUploadStart(files));
    this.uppy.on('cancel-all', () => this.onUploadCancel());


    this.fileInputTarget.addEventListener("change", (event) => {
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        });
      });
    });
  }

  refreshFilesList() {
    if (this.filesList) {
      const files = JSON.parse(this.cachedFileInputTarget.value || "[]");
      this.filesList.innerHTML = "";

      files.forEach((file) => {
        const li = document.createElement("li");
        const span = document.createElement("span");
        const deleteBtn = document.createElement("button");

        span.innerHTML = file.metadata.filename;
        deleteBtn.innerHTML = this.uppyLocale().strings.removeFile.replace(" %{file}", "");

        deleteBtn.addEventListener("click", (e) => this.onDeleteFile(e, file));

        li.appendChild(span);
        li.appendChild(deleteBtn);

        this.filesList.appendChild(li);
      });
    }
  }
}
