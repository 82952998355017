import {Controller} from "@hotwired/stimulus"
import {indentWithTab} from "@codemirror/commands"
import {json} from "@codemirror/lang-json"
import {syntaxHighlighting, defaultHighlightStyle} from "@codemirror/language"
import {EditorState} from "@codemirror/state"
import {EditorView, keymap} from "@codemirror/view"

// Connects to data-controller="code-editor"
export default class extends Controller {
  static targets = ["textEditor", "textInput"];

  connect() {
    const textEditor = this.textEditorTarget;
    const textInput = this.textInputTarget;

    const startState = EditorState.create({
      doc: textInput.value,
      extensions: [
        json(),
        keymap.of([indentWithTab]),
        syntaxHighlighting(defaultHighlightStyle)
      ]
    })

    const view = new EditorView({
      state: startState,
      parent: textEditor
    })

    this.element.addEventListener("submit", function () {
      textInput.value = view.state.doc.toString();
    });
  }
}
