import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="checkall"
export default class extends Controller {
  static targets = ["checkall", "checkboxes"]

  connect() {
  }

  checkallTargetConnected(target) {
    target.addEventListener('click', () => {
      const checkboxes = this.checkboxesTargets;
      for (const checkbox of checkboxes) {
        checkbox.checked = target.checked;
      }
    });
  }

  checkboxesTargetConnected(target) {
    target.addEventListener("click", () => {
      const checkall = this.checkallTarget;

      if (this.checkboxesTargets.every((t) => t.checked)) {
        checkall.checked = true;
        checkall.indeterminate = false;
      } else if (this.checkboxesTargets.some((t) => t.checked)) {
        checkall.checked = false;
        checkall.indeterminate = true;
      } else {
        checkall.checked = false;
        checkall.indeterminate = false;
      }
    });
  }
}
