import Masonry from "masonry-layout";

import {Controller} from "@hotwired/stimulus";

import imagesLoaded from "imagesloaded";

// Connects to data-controller="masonry"
export default class extends Controller {
  static targets = ["masonryBrick", "pagination"];

  connect() {
    document.addEventListener("turbo:before-stream-render", (event) => {
      const originalRender = event.detail.render;

      event.detail.render = (streamElement) => {
        originalRender(streamElement);
        this.updateDynamicItems();
      };
    });

    this.initializeMansory();
  }

  initializeMansory() {
    this.grid = this.element.querySelector(".masonry-container");

    this.temporaryContainer = this.element.querySelector(".masonry-temporary");

    this.masonry = new Masonry(this.grid, {
      itemSelector: ".masonry-brick",
      columnWidth: ".masonry-sizer",
    });

    this.updateDynamicItems();
  }

  updateDynamicItems() {
    const newItems = this.temporaryContainer.querySelectorAll(".masonry-brick");

    if (newItems.length === 0) return;

    this.element.classList.add("loading");

    newItems.forEach((item) => {
      item.classList.add("invisible");
      this.grid.append(item);
    });

    imagesLoaded(newItems, (e) => {
      this.masonry.appended(newItems);
      newItems.forEach((item) => item.classList.remove("invisible"));
      this.element.classList.remove("loading");
      this.masonry.layout();
    });
  }

  paginationTargetConnected(target) {
    target.addEventListener("click", () => this.element.classList.add("loading"));
  }
}
