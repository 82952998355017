import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="popper"
/**
 * Popper triggers are handled 'manual' because it doesn't support out of the box the ability to
 * let the user hover the content of the tooltip.
 */
export default class extends Controller {
  static targets = ["tooltip"];

  tooltipTargetConnected(target) {
    this.tooltips ||= new Map();
    this.hideInterval ||= new Map();

    this.tooltips.set(target, new Bootstrap.Popover(target, {html: true, sanitize: false, trigger: "manual"}));

    target.addEventListener("mouseenter", () => {
      if(target.dataset.bsOverflowOnly !== "true"  || (target.offsetWidth < target.scrollWidth)){
        if (this.isHidding(target)) {
          this.cancelHide(target)
        } else {
          this.tooltips.get(target).show();
        }
      }
    });

    target.addEventListener("mouseleave", () => {
      this.hide(target);
    });

    target.addEventListener("shown.bs.popover", () => {
      document.querySelector('.popover').addEventListener("mouseenter", () => {
        if (this.isHidding(target)) {
          this.cancelHide(target);
        }
      });

      document.querySelector('.popover').addEventListener("mouseleave", () => {
        this.hide(target);
      });
    })

    target.addEventListener("hide.bs.popover", () => {
      this.hideInterval.delete(target);
    });
  }

  tooltipTargetDisconnected(target) {
    if (this.tooltips.get(target)) {
      this.tooltips.get(target).dispose();
      this.tooltips.delete(target);
    }
  }

  isHidding(target) {
    return this.hideInterval.has(target);
  }

  hide(target) {
    if (this.isHidding(target)) this.cancelHide(target);

    this.hideInterval.set(target, setTimeout(() => {
      this.hideInterval.delete(target);
      this.tooltips.get(target).hide();
    }, 10));
  }

  cancelHide(target) {
    clearInterval(this.hideInterval.get(target));
    this.hideInterval.delete(target);
  }
}